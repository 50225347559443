import { call, put } from "redux-saga/effects";
import { API_URLS } from "../resources/constants";
// import { updateMappedAcc } from "../store/user";
import { formQueryString } from '../resources/functions'
import {CallApi} from './callApi'

export function* list(action: any): any {
    try {
      const payload = action.payload;
      const url = API_URLS.GL_CLASSIFICATION.GL_TRANS_LIST + '?' + formQueryString(payload);
      let result = yield call(CallApi.GET, url,{},true);
      //yield put(updateUser(result.data));
      action && action.callbackSuccess(result);
    } catch (e) {
      action && action.callbackError(e)
    }
}

export function* reconcileTxnList(action: any): any {
  try {
    const payload = action.payload;
    const url = API_URLS.GL_CLASSIFICATION.GL_RECONCILE_TRANS_LIST + '?' + formQueryString(payload);
    let result = yield call(CallApi.GET, url,{},true);
    //yield put(updateUser(result.data));
    action && action.callbackSuccess(result);
  } catch (e) {
    action && action.callbackError(e)
  }
}
export function* reconcileOpenningBalanceFetch(action: any): any {
  try {
    const payload = action.payload;
    const url = API_URLS.GL_CLASSIFICATION.GL_RECONCILE_OPENNING_BALANCE_FETCH + '?' + formQueryString(payload);
    let result = yield call(CallApi.GET, url,{},true);
    //yield put(updateUser(result.data));
    action && action.callbackSuccess(result);
  } catch (e) {
    action && action.callbackError(e)
  }
}
export function* reconcilePost(action: any): any {
  try {
    const payload = action.payload;
    let result = yield call(CallApi.POST, API_URLS.GL_CLASSIFICATION.GL_RECONCILE_TRANS_POST + `?branch_id=${payload['branch_id']}`,payload['formData'], true);
    action && action.callbackSuccess(result);
  } catch (e) {
    action && action.callbackError(e)
  }
}
export function* saleList(action: any): any {
  try {
    const payload = action.payload;
    const url = API_URLS.GL_CLASSIFICATION.GL_SALES_LIST + '?' + formQueryString(payload);
    let result = yield call(CallApi.GET, url,{},true);
    //yield put(updateUser(result.data));
    action && action.callbackSuccess(result);
  } catch (e: any) {
    /*if ( e && e['data'] && e['data']['sales_activity_match'] === false ) {
      yield put(updateMappedAcc({
        sales_activity_match: false
      }));
    }*/
    action && action.callbackError(e)
  }
}
export function* bankGlUpdate(action: any): any {
    try {
      const payload = action.payload;
      let result = yield call(CallApi.PUT, API_URLS.GL_CLASSIFICATION.BANK_GL_UPDATE + `?branch_id=${payload['branch_id']}`,payload['records'], true);
      action && action.callbackSuccess(result);
    } catch (e) {
      action && action.callbackError(e)
    }
}
export function* saleGlUpdate(action: any): any {
  try {
    const payload = action.payload;
    let result = yield call(CallApi.PUT, API_URLS.GL_CLASSIFICATION.SALE_GL_UPDATE + `?branch_id=${payload['branch_id']}`,payload['records'], true);
    action && action.callbackSuccess(result);
  } catch (e) {
    action && action.callbackError(e)
  }
}
export function* revenueGlUpdate(action: any): any {
  try {
    const payload = action.payload;
    let result = yield call(CallApi.PUT, API_URLS.GL_CLASSIFICATION.REVENUE_GL_UPDATE + `?branch_id=${payload['branch_id']}`,payload['records'], true);
    action && action.callbackSuccess(result);
  } catch (e) {
    action && action.callbackError(e)
  }
}
export function* classificationStatus(action: any): any {
  try {
    const payload = action.payload;
    const url = API_URLS.GL_CLASSIFICATION.CLASSIFICATION_STATUS + '?' + formQueryString(payload);
    let result = yield call(CallApi.GET, url ,payload, true);
    action && action.callbackSuccess(result);
  } catch (e) {
    action && action.callbackError(e)
  }
}
export function* completeStatus(action: any): any {
  try {
    const payload = action.payload;
    const { branch_id, type, ...params} = payload;
    const url = API_URLS.GL_CLASSIFICATION.COMPLETE_STEP + '?branch_id='+branch_id+'&type='+type;
    let result = yield call(CallApi.PUT, url, params, true);
    action && action.callbackSuccess(result);
  } catch (e) {
    action && action.callbackError(e)
  }
}
export function* skipBeginningBalance(action: any): any {
  try {
    const payload = action.payload;
    const { branch_id } = payload;
    const url = API_URLS.GL_CLASSIFICATION.SKIP_BEGINNING_BALANCE + '?branch_id='+branch_id;
    let result = yield call(CallApi.POST, url, {}, true);
    action && action.callbackSuccess(result);
  } catch (e) {
    action && action.callbackError(e)
  }
}